<!-- 入库单详情 -->
<template>
  <div v-loading="loading" class="asnBillDetail">
    <div class="headerBtns">
      <el-button size="small" type="primary" round icon="iconfont icon-zuo" @click="$router.go(-1)"> {{ $t(`m.common.back`) }} </el-button>
    </div>
    <el-row>
      <el-col :span="24">
        <el-card>
          <div class="searchBar">
            <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;">
              <el-tab-pane :label="isFile ? $t(`m.asn.archive_information_inbound_warehouse_receipt_documents`) : $t(`m.asn.details_receipt_slip`)" name="first">
                <el-form v-model="formData" label-width="120px">
                  <el-row>
                    <el-col :span="6">
                      <el-form-item :label="$t(`m.asn.inventory_status`)">
                        <el-tag effect="plain" :type="asnStates.find(item =>  Number(item.value) == asnMaster.asnState)?.type">
                          {{ asnStates.find(item => Number(item.value) == asnMaster.asnState)?.label }}
                        </el-tag>
                      </el-form-item>
                      <el-form-item :label="$t(`m.common.inventory_type`)">
                        <span>{{ formatListDataByKey(asnMaster.billType, false, asnStatusTypes, 'label', 'value' ) }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item :label="$t(`m.warehousingCommon.warehouse_storage`)">
                        <!--  @click="$parent.showPublicModal(asnMaster.wareId, 'ware')" -->
                        <span :title="asnMaster.wareName" class="textBtn">
                          {{ asnMaster.wareName||'-' }}</span>
                      </el-form-item>
                      <el-form-item :label="$t(`m.warehousingCommon.logistics_tracking_number`)">
                        <span>{{ asnMaster.shipBillCode||'-' }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item :label="$t(`m.warehousingCommon.associated_order_number`)">
                        <span>{{ asnMaster.requestBuyBillNo||'-' }}</span>
                      </el-form-item>
                      <el-form-item :label="$t(`m.warehousingCommon.inventory_receipt_number`)">
                        <span :title="asnMaster.billNo">&nbsp;{{ asnMaster.billNo||'-' }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item :label="$t(`m.warehousingCommon.logistics_provider`)">
                          {{ asnMaster.supplierName || formatListDataByKey(asnMaster.carrierCode, false, carriers, 'carrierName', 'carrierCode') }}
                      </el-form-item>
                      <el-form-item :label="$t(`m.common.remark`)">
                        <el-tooltip popper-class="custom-tooltip" effect="dark" :content="asnMaster.memo.split('&').join('')" placement="top" v-if="asnMaster.memo">
                          <div class="no-wrap">{{asnMaster.memo.split('&').join('')}}</div>
                        </el-tooltip>
                        <span v-else>-</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-card>
        <el-card>
          <el-tabs type="card" v-model="activeName2" @tab-click="handleClick">
            <el-tab-pane :label="$t(`m.warehousingCommon.product_information`)" name="first">
              <div class="tableBox">
                <el-table
                  ref="detailTable"
                  height="400"
                  stripe
                  highlight-current-row
                  @selection-change="handleSelectionChange"
                  :data="asnDetails"
                  style="width: 100%;"
                  :header-cell-style="$parent.tableHeaderColor"
                  border
                  show-summary :summary-method="getSummaries"
                >
                  <el-table-column type="index" :label="$t(`m.common.no`)" width="60"> </el-table-column>
                  <el-table-column prop="img" :label="$t(`m.common.picture`)">
                      <template slot-scope="scope">
                        <el-image style="width: 80px; height: 80px" :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]">
                        </el-image>
                        <!-- <img v-bind:src="scope.row.img_url"  style="width:80px;height:86px"> -->
                      </template>
                  </el-table-column>
                  <el-table-column show-overflow-tooltip prop="itemName" :label="'SKU/' + $t(`m.common.product_name`)">
                    <template slot-scope="scope">
                      <!-- <div
                        @click="$parent.showPublicModal(scope.row.itemCode, 'item')"
                      > -->
                        <span>{{ scope.row.itemCode }}</span>
                        <br />
                        <span>{{ scope.row.itemName }}</span>
                        <br />
                      <!-- </div> -->
                    </template>
                  </el-table-column>
                  <el-table-column show-overflow-tooltip :label="$t('m.warehousingCommon.status')">
                    <template slot-scope="scope">
                      <el-tag :type="asnStates.find(item => Number(item.value) === scope.row.state)?.type">
                        {{ asnStates.find(item => Number(item.value) === scope.row.state)?.label }}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="expectQuantity" :label="$t('m.warehousingCommon.receivable_quantity_goods')"> </el-table-column>
                  <el-table-column prop="acceptQuantity" :label="$t('m.asn.received_quantity')"> </el-table-column>
                  <el-table-column prop="dsNumber" :label="$t('m.asn.pending_collection_quantity')"> </el-table-column>
                  <!-- <el-table-column
                    v-if="
                      (asnMaster.insertType === 0 && asnMaster.asnState !== 3) ||
                        (asnMaster.insertType === 0 && asnMaster.asnState !== 5) ||
                        (asnMaster.insertType === 0 && asnMaster.asnState !== 4)
                    "
                    fixed="right"
                    :label="$t(`m.common.operation`)"
                    min-width="60"
                  >
                    <template slot-scope="scope">
                      <el-button
                        @click="showAcceptModal(scope.row)"
                        icon="iconfont icon-shouhuo"
                        type="success"
                        circle
                        plain
                        size="small"
                      ></el-button>
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t(`m.asn.inventory_record`)" name="third">
              <div class="tableBox">
                <el-table highlight-current-row :data="acceptRecords" :header-cell-style="$parent.tableHeaderColor" border>
                  <el-table-column type="index" :label="$t(`m.common.no`)" key="1" width="50"> </el-table-column>
                  <el-table-column prop="img" :label="$t(`m.common.picture`)" key="2">
                      <template slot-scope="scope">
                        <el-image style="width: 80px; height: 80px" :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]">
                        </el-image>
                      </template>
                  </el-table-column>
                  <el-table-column key="3" show-overflow-tooltip prop="itemName" :label="'SKU/' + $t(`m.common.product_name`)" width="260">
                    <template slot-scope="scope">
                        <span>{{ scope.row.itemCode }}</span>
                        <br />
                        <span>{{ scope.row.itemName }}</span>
                        <br />
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop=" " label="关联上架单据"> </el-table-column> -->
                  <el-table-column key="4" prop="expectQuantity" :label="$t(`m.warehousingCommon.operation_type`)">
                    <template slot-scope="scope">
                      <span v-if="scope.row.quantity < 0">上架单强制完结</span>
                      <span v-if="scope.row.quantity > 0">收货</span>
                    </template>
                  </el-table-column>
                  <el-table-column key="5" prop="quantity" :label="$t(`m.warehousingCommon.operations_num`)"> </el-table-column>
                  <el-table-column key="6" prop="cellCode" :label="$t(`m.warehousingCommon.location_code`)" width="200"> </el-table-column>
                  <el-table-column key="7" prop="createTime" :label="$t(`m.warehousingCommon.operation_time`)" width="200"> </el-table-column>
                  <el-table-column key="8" prop="acceptUserName" :label="$t(`m.warehousingCommon.operator`)" width="200"> </el-table-column>
                  <!-- <el-table-column fixed="right" :label="$t(`m.common.operation`)" min-width="60">
                    <template slot-scope="scope">
                      <el-button type="success" circle plain size="small">修改</el-button>
                    </template>
                  </el-table-column> -->
                </el-table>
                <el-pagination
                  class="pagination-bottom"
                  style="text-align: right;"
                  @current-change="handleCurrentChangeThird"
                  :current-page.sync="searchDataThird.pageNum"
                  :page-size="searchDataThird.pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="searchDataThird.total"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t(`m.common.log`)" name="2">
                <div class="tableBox none">
                <el-table border tooltip-effect="dark myTooltips"
                highlight-current-row :data="exDetails" style="width: 100%" :header-cell-style="$parent.tableHeaderColor">
                    <el-table-column type="index" width="50" :label="$t(`m.common.no`)"> </el-table-column>
                    <el-table-column prop="createTime" :label="$t(`m.warehousingCommon.operation_time`)"> </el-table-column>
                    <el-table-column prop="serviceText" :label="$t(`m.warehousingCommon.operating_procedures`)" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="userName" :label="$t(`m.warehousingCommon.operator`)"> </el-table-column>
                </el-table>
                <el-pagination
                    class="pagination-bottom"
                    style="text-align: right;"
                    @current-change="handleCurrentChange"
                    :current-page.sync="logSearchData.pageNum"
                    :page-size="logSearchData.pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="logSearchData.total"
                >
                </el-pagination>
                </div>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { formatListDataByKey } from '@/utils/DateUtils.js'
  import _ from 'lodash'
  

  export default {
    name: 'AsnBillDetail',
    data() {
      return {
        formData: {},
        loading: false,
        isMoreSearch: false,
        isMoreSearchMaster: true,
        isMoreSearch2: false,
        isMoreSearch3: false,
        activeName: 'first',
        activeName2: 'first',
        searchData: {
          billNo: this.$route.params.billNo,
        },
        searchDataThird: {
          billNo: this.$route.params.billNo,
          pageNum: 1,
          pageSize: 10,
          total: 0
        },
        searchDataItem: {
          pageNum: 1,
          pageSize: 5,
          total: null,
          keyWords: '',
          wareId: null,
          organizationId: null,
        },
        searchSelectCell: {
          pageNum: 1,
          pageSize: 5,
          total: null,
          keyWords: '',
          wareId: '',
          areaId: '',
          cellUseType: 0,
        },

        asnMaster: {},
        asnDetails: [],
        asnShipDetails: [],
        acceptRecords: [],
        exDetails: [],
        form: {
          asnMaster: {},
          asnDetails: [],
        },
        asnUdfHs: [],
        asnUdfDs: [],
        asnTypes: [],
        ids: [],
        items: [],
        suppliers: [],
        organizations: [],
        platforms: [],
        carriers: [],
        multipleSelection: [],
        multipleSelection2: [],
        qcUdfHs: [],
        qcUdfDs: [],
        qcTypes: [],
        batchTactics: [],
        cells: [],
        areas: [],
        packDetails: [],
        acceptExcess: null,

        innerVisibleItem: false,
        dialogNewVisible: false,
        dialogQcVisible: false,
        dialogReviewVisible: false,
        dialogAcceptVisible: false,
        innerVisibleCell: false,

        rules: {
          supplierId: [
            {
              required: true,
              message: '请选择货主',
              trigger: 'blur',
            },
          ],
          buyType: [
            {
              required: true,
              message: '请选择供应商',
              trigger: 'blur',
            },
          ],
        },

        formLabelWidth: '120px',

        formQc: {
          billSource: 0,
          insertType: -2,
          qcMaster: {},
          qcDetails: [],
        },
        rulesQc: {
          asnBillNo: [
            {
              required: true,
              message: '请选择货主',
              trigger: 'blur',
            },
          ],
          organizationId: [
            {
              required: true,
              message: '请选择供应商',
              trigger: 'blur',
            },
          ],
          supplierId: [
            {
              required: true,
              message: '请选择货主',
              trigger: 'blur',
            },
          ],
          qcType: [
            {
              required: true,
              message: '请选择质检类型',
              trigger: 'blur',
            },
          ],
        },

        formReview: {
          billNo: this.$route.params.billNo,
          reviewResult: null,
          caseDetailId: null,
          memo: '',
        },
        rulesReview: {
          memo: [
            {
              required: true,
              message: '请填写审核原因',
              trigger: 'blur',
            },
          ],
        },

        formAccept: {
          asnBillNo: '',
          asnDetailNo: '',
          wareId: null,
          wareName: '',
          organizationId: null,
          organizationName: '',
          toPackDetailId: null,
          toTransRatio: null,
          toPackDescribe: '',
          itemCode: '',
          itemName: '',
          packCode: '',
          toCellCode: '',
          toBoxCode: '',
          toQuantity: 0,
          billType: 2,
          expectQuantity: 0,
          acceptQuantity: 0,
          productDate: '',
          exDate: '',
          inDate: '',
          supplierCode: '',
          supplierBatch: '',
          qcState: '',
          costPrice: null,
          salePrice: '',
          detailNo: '',
          memo1: '',
          memo2: '',
          memo3: '',
          batchAttribute1: '',
          batchAttribute2: '',
        },
        rulesAccept: {
        },
        logSearchData: {
            wareId: PF.getLocal('wareId'),
            serviceNumber: this.$route.params.billNo,
            serviceCode: 'asn_master',
            pageNum: 1,
            pageSize: 15,
            total: null,
        }
      };
    },
    computed: {
      ...mapState(['abcLevels', 'qcStates', 'cellUseTypes', 'cellAbcLevels', 'cellPickTypes'
      , 'asnStates', 'asnStatusTypes']),
      supplierId() {
        return this.form.asnMaster.supplierId;
      },
      isFile() {
        if (this.$route.params.fileUserId) {
          return true;
        } else {
          return false;
        }
      },
    },
    watch: {
      supplierId(val) {
        for (let i = 0; i < this.suppliers.length; i++) {
          if (this.suppliers[i].supplierId === val) {
            this.form.asnMaster.supplierName = this.suppliers[i].supplierName;
          }
        }
      },
    },
    methods: {
      formatListDataByKey,
      // 获取产品信息列表
      getAsnDetailData() {
        this.loading = true;
        let url = '/asn/masters/findByBillNoList';
        IOT.getServerData(url, 'post', this.searchData, (ret) => {
          this.loading = false;
          if (ret.code === 200) {
            let list = ret.rows;
            // for (let i = 0; i < list.length; i++) {
            //   list[i].packExpectQuantity = list[i].expectQuantity / list[i].transRatio;
            //   list[i].packAcceptQuantity = list[i].acceptQuantity / list[i].transRatio;
            // }
            this.asnDetails = _.map(ret.rows.detailList, item => {
              item.dsNumber = (Number(item.expectQuantity||0)-Number(item.acceptQuantity||0)) || 0 //代收量
              return item
            });
            console.log(this.asnDetails, 'asnDetails');
            
            this.searchData.total = ret.total;
            this.searchData.pageNum = ret.pageNumber;
            this.$nextTick(() => {
              this.$refs.detailTable.doLayout();
            });
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
      },
      // 合计产品信息
      getSummaries(param) {
          const { columns, data } = param;
          const sums = [];
          columns.forEach((column, index) => {
          if (index === 0) {
              sums[index] = this.$t(`m.common.total`);
              return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                  return prev + curr;
              } else {
                  return prev;
              }
              }, 0);
          } else {
              sums[index] = '';
          }
        });
        return sums;
      },
      // 入库记录
      getAsnRecordData() {
        this.acceptRecords = [];
        IOT.getServerData(
          '/accept/records/list',
          'post',
          {
            asnBillNo: this.searchDataThird.billNo,
            pageNum: this.searchDataThird.pageNum,
            pageSize: this.searchDataThird.pageSize,
          },
          (ret) => {
            if (ret.code === 200) {
              this.acceptRecords = _.map(ret.rows, item => {
                return item
              });
              this.searchDataThird.total = ret.total;
              this.searchDataThird.pageNum = ret.pageNumber;
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          }
        );
      },
      //入库记录分页
      handleCurrentChangeThird(val) {
        this.searchDataThird.pageNum = val;
        this.getAsnRecordData();
      },  
      exportAns() {
        let url = '/asn/masters/daochu';
        IOT.showOverlay('正在导出，请稍等...');
        IOT.getServerData(
          url,
          'get',
          {
            asnMasterId: this.asnMaster.asnMasterId,
          },
          (ret) => {
            if (ret.code === 200) {
              debugger;
              if (ret.code === 200) {
                var path = ret.data;
                if (path != undefined && path != null) {
                  window.location.href = path;
                }

                //   window.open(path);
              } else {
                IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
              }
              IOT.hideOverlay();
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          },
          true
        );
      },

      handleClick(tab, event) {
        console.log(tab, event);
      },
      handleCurrentChange() {
        this.logSearchData.pageNum = val;
        this.getLogList()
      },
      clearSearchSelectItem() {
        this.searchDataItem.keyWords = '';
        this.searchDataItem.wareId = null;
        this.searchDataItem.organizationId = null;
      },
      clearSearchSelectCell() {
        this.searchSelectCell.keyWords = '';
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      printList() {
        this.$router.push({
          name: 'printView',
          params: {
            printList: this.multipleSelection,
          },
        });
      },
      getAsnMasterData() {
        this.loading = true;
        let url = '';
        if (this.isFile) {
          url = '/asn/master/files/list';
        } else {
          url = '/asn/masters/findByBillNoList';
        }
        IOT.getServerData(
          url,
          'post',
          {
            billNo: this.searchData.billNo,
          },
          (ret) => {
            this.loading = false;
            if (ret.code === 200) {
              let list = ret.rows;
              // if (PF.isNull(list.createTime)) {
              //   list.createTime1 = list.createTime.substr(5, 11);
              // }
              // if (PF.isNull(list.expectTime)) {
              //   list.expectTime1 = list.expectTime.substr(5, 11);
              // }
              this.asnMaster = list;
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          }
        );
      },
      getAsnShipDetailData() {
        let url = '/asn/ship/details/findByBillNo';

        IOT.getServerData(
          url,
          'get',
          {
            billNo: this.searchData.billNo,
          },
          (ret) => {
            if (ret.code === 200) {
              this.asnShipDetails = ret.rows;
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          }
        );
      },
      getItemData() {
        IOT.getServerData('/item/infos/list', 'get', this.searchDataItem, (ret) => {
          if (ret.code === 200) {
            this.items = ret.rows;
            this.searchDataItem.total = ret.total;
            this.searchDataItem.pageNum = ret.pageNumber;
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
      },
      getPlatformData() {
        IOT.getServerData(
          '/platforms/list',
          'get',
          {
            wareId: this.asnMaster.wareId,
          },
          (ret) => {
            if (ret.code === 200) {
              let list = ret.rows;
              for (let i = 0; i < list.length; i++) {
                list[i].label = list[i].platformCode;
              }
              this.platforms = list;
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          }
        );
      },
      getCarrierData() {
        IOT.getServerData('/carriers/list', 'post', {}, (ret) => {
          if (ret.code === 200) {
            this.carriers = ret.rows;
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
      },
      getCellData() {
        IOT.getServerData('/cell/infos/list', 'get', this.searchSelectCell, (ret) => {
          if (ret.code === 200) {
            this.cells = ret.rows;
            this.searchSelectCell.total = ret.total;
            this.searchSelectCell.pageNum = ret.pageNumber;
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
      },
      getAreaData() {
        IOT.getServerData(
          '/area/infos/list',
          'get',
          {
            wareId: this.searchSelectCell.wareId,
          },
          (ret) => {
            if (ret.code === 200) {
              this.areas = ret.rows;
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          }
        );
      },
      getPackDetailData(packCode) {
        IOT.getServerData(
          '/pack/details/findByPackCode',
          'get',
          {
            packCode: packCode,
          },
          (ret) => {
            if (ret.code === 200) {
              this.packDetails = ret.rows;
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          }
        );
      },
      handleClose(done) {
        this.dialogNewVisible = false;
      },
      handleClose2(done) {
        this.formQc.qcDetails = [];
        this.dialogQcVisible = false;
      },
      handleClose3(done) {
        this.dialogReviewVisible = false;
      },
      handleClose4(done) {
        this.resetForm('formAccept');
        this.dialogAcceptVisible = false;
      },
      isDelete(id) {
        this.$confirm('确认删除？')
          .then((_) => {
            this.deleteBuyBillIn(id);
          })
          .catch((_) => {});
      },
      deleteBuyBillIn(id) {
        IOT.showOverlay('删除中，请稍等...');
        IOT.getServerData(
          '/asn/masters/delete',
          'get',
          {
            id: id,
          },
          (ret) => {
            IOT.hideOverlay();
            if (ret.code === 200) {
              this.$router.go(-1);
              IOT.tips('删除成功！', 'success');
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          }
        );
      },
      showUpdateModal() {
        this.form = {
          insertType: -2,
          asnMaster: PF.JSON(this.asnMaster),
          asnDetails: PF.JSON(this.asnDetails),
        };
        PF.getOrganizations((rows) => {
          this.organizations = rows;
        });
        this.getPlatformData();
        this.getCarrierData();
        this.dialogNewVisible = true;
      },
      showSelectItemModal() {
        this.ids = [];
        for (let i = 0; i < this.form.asnDetails.length; i++) {
          this.ids.push(this.form.asnDetails[i].itemCode);
        }
        this.searchDataItem.wareId = PF.getLocal('wareId', 'number');
        this.searchDataItem.organizationId = this.form.asnMaster.organizationId;
        this.getItemData();
        this.innerVisibleItem = true;
      },
      selectItem(row) {
        if (this.ids.indexOf(row.itemCode) !== -1) {
          IOT.tips('相同产品不能重复添加！', 'error');
          this.innerVisibleItem = false;
          return false;
        }
        this.ids.push(row.itemCode);
        IOT.getServerData(
          '/pack/details/list',
          'get',
          {
            packCode: row.packCode,
          },
          (ret) => {
            if (ret.code === 200) {
              let list = ret.rows;
              row.packDetails = [];
              for (let i = 0; i < list.length; i++) {
                row.packDetails.push({
                  packDetailId1: list[i].packDetailId,
                  packDescribe1: list[i].packDescribe,
                  transRatio: list[i].transRatio,
                });
              }
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          },
          false,
          false
        );
        this.form.asnDetails.push(row);
        this.form.asnDetails.expectQuantity = 0;
        this.innerVisibleItem = false;
      },
      deleteDetail(index) {
        this.form.asnDetails.splice(index, 1);
      },
      updateForm() {
        let that = this;
        let form = this.form;
        if (form.asnDetails.length <= 0) {
          IOT.tips('未选择产品,请重新选择！', 'error', 1000, () => {
            IOT.hideOverlay();
          });
          return false;
        }
        delete form.asnMaster.createTime;
        for (let i = 0; i < form.asnDetails.length; i++) {
          if (form.asnDetails[i].expectQuantity === 0 || !PF.isNull(form.asnDetails[i].expectQuantity)) {
            IOT.tips('数量不得为零,请重新输入！', 'error', 1000, () => {
              IOT.hideOverlay();
            });
            return false;
          }
          // 主单位数量
          form.asnDetails[i].expectQuantity = form.asnDetails[i].expectQuantity * form.asnDetails[i].transRatio;
        }
        IOT.getServerData('/asn/masters/update', 'post', form, (ret) => {
          IOT.hideOverlay();
          if (ret.code === 200) {
            IOT.tips('保存成功！', 'success', 1000, () => {
              that.getAsnMasterData();
              that.getAsnDetailData();
            });
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
        this.dialogNewVisible = false;
      },
      isCommit() {
        this.$confirm('确认提交？')
          .then((_) => {
            this.commitForm();
          })
          .catch((_) => {});
      },
      commitForm() {
        IOT.showOverlay('保存中，请稍等...');
        let form = {
          billSource: this.asnMaster.billSource,
          insertType: 0,
          asnMaster: PF.JSON(this.asnMaster),
          asnDetails: PF.JSON(this.asnDetails),
        };
        if (form.asnDetails.length <= 0) {
          IOT.tips('未选择产品,请重新选择！', 'error', 1000, () => {
            IOT.hideOverlay();
          });
          return false;
        }
        for (let i = 0; i < form.asnDetails.length; i++) {
          if (form.asnDetails[i].expectQuantity === 0) {
            IOT.tips('数量不得为零,请重新输入！', 'error', 1000, () => {
              IOT.hideOverlay();
            });
            return false;
          }
          // 主单位数量
          form.asnDetails[i].expectQuantity = form.asnDetails[i].expectQuantity * form.asnDetails[i].transRatio;
        }
        IOT.getServerData('/asn/masters/insert', 'post', form, (ret) => {
          IOT.hideOverlay();
          if (ret.code === 200) {
            this.$router.push({
              name: 'asnBill',
            });
            IOT.tips('保存成功！', 'success');
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
      },
      getCreateList(data, callback) {
        IOT.getServerData(
          '/qc/details/getCreateList',
          'get',
          {
            asnBillNo: data.billNo,
            asnType: data.asnType,
          },
          (ret) => {
            IOT.hideOverlay();
            if (ret.code === 200) {
              callback(ret.rows);
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          },
          false,
          false
        );
      },
      showQcModal() {
        let formQc = {
          billSource: 0,
          insertType: -2,
          qcMaster: PF.JSON(this.asnMaster),
          qcDetails: [],
        };
        this.getCreateList(this.asnMaster, (data) => {
          let list = data;
          let itemCodes = [];
          for (let i = 0; i < list.length; i++) {
            itemCodes.push(list[i].itemCode);
            // list[i].expectQuantity = list[i].expectQuantity / list[i].transRatio;
            list[i].qcQuantity = list[i].qcQuantity / list[i].transRatio;
          }
          let asnDetails = PF.JSON(this.asnDetails);
          if (list.length > asnDetails.length) {
            for (let j = 0; j < asnDetails.length; j++) {
              if (itemCodes.indexOf(asnDetails[j].itemCode)) {
                asnDetails[j].expectQuantity = null;
                asnDetails[j].qcQuantity = null;
                formQc.qcDetails.push(asnDetails[j]);
              }
            }
          }
          formQc.qcDetails = list;
        });
        formQc.qcMaster.insertType = -2;
        formQc.qcMaster.qcType = 1;
        formQc.qcMaster.asnBillNo = formQc.qcMaster.billNo;
        delete formQc.qcMaster.billNo;
        formQc.qcMaster.wareId = PF.getLocal('wareId', 'number');
        formQc.qcMaster.wareName = PF.getLocal('wareName');

        for (let i = 0; i < formQc.qcDetails.length; i++) {
          formQc.qcDetails[i].oldQ = formQc.qcDetails[i].expectQuantity;
          formQc.qcDetails[i].oldP = formQc.qcDetails[i].packDetailId;
          // formQc.qcDetails[i].asnDetailNo = formQc.qcDetails[i].detailNo;
          delete formQc.qcDetails[i].detailNo;
          delete formQc.qcDetails[i].billNo;
        }

        this.formQc = formQc;
        PF.getOrganizations((rows) => {
          this.organizations = rows;
        });
        PF.getSuppliers((rows) => {
          this.suppliers = rows;
        });
        PF.getSysParamUdfData('qcUdfHs', [this.formQc.qcMaster], (data, udfs) => {
          this.formQc.qcMaster = data[0];
          this.qcUdfHs = udfs;
        });
        PF.getSysParamUdfData('qcUdfDs', this.formQc.qcDetails, (data, udfs) => {
          this.formQc.qcDetails = data;
          this.qcUdfDs = udfs;
        });
        this.dialogQcVisible = true;
      },
      changePackDetailId(index, type) {
        if (type === 'qc') {
          let detail = PF.JSON(this.formQc.qcDetails[index]);
          for (let i = 0; i < detail.packDetailsList.length; i++) {
            if (detail.packDetailsList[i].packDetailId === detail.packDetailId) {
              this.formQc.qcDetails[index].packDescribe = detail.packDetailsList[i].packDescribe;
              this.formQc.qcDetails[index].transRatio = detail.packDetailsList[i].transRatio;
            }
          }
          // this.formQc.qcDetails[index].expectQuantity = PF.changePToChangeQ(this.formQc.qcDetails[index]);
        } else if (type === 'update') {
          let detail = PF.JSON(this.form.asnDetails[index]);
          for (let i = 0; i < detail.packDetails.length; i++) {
            if (detail.packDetails[i].packDetailId1 === detail.packDetailId) {
              this.form.asnDetails[index].packDescribe = detail.packDetails[i].packDescribe1;
              this.form.asnDetails[index].transRatio = detail.packDetails[i].transRatio1;
            }
          }
          // this.form.asnDetails[index].expectQuantity = PF.changePToChangeQ(this.form.asnDetails[index]);
        } else if (type === 'accept') {
          for (let i = 0; i < this.packDetails.length; i++) {
            if (this.packDetails[i].packDetailId === this.formAccept.toPackDetailId) {
              this.formAccept.toPackDescribe = this.packDetails[i].packDescribe;
              this.formAccept.toTransRatio = this.packDetails[i].transRatio;
            }
          }
          // this.form.asnDetails[index].expectQuantity = PF.changePToChangeQ(this.form.asnDetails[index]);
        }
      },
      resetForm(formName) {
        // 重置
        this.$refs[formName].resetFields();
      },
      commitFormQc(formName, insertType) {
        IOT.showOverlay('保存中，请稍等...');
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let formQc = this.formQc;
            formQc.insertType = insertType;
            // formQc.qcDetailList = PF.JSON(formQc.qcDetails);
            // delete formQc.qcDetails;
            IOT.getServerData('/qc/masters/insert', 'post', formQc, (ret) => {
              IOT.hideOverlay();
              if (ret.code === 200) {
                this.$router.push({
                  name: 'asnBill',
                });
                IOT.tips('保存成功！', 'success');
              } else {
                IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
              }
            });
            this.resetForm(formName);
            this.handleClose2();
          } else {
            IOT.hideOverlay();
            console.error('error submit!!');
            return false;
          }
        });
      },
      commitReview(formName, reviewResult) {
        IOT.showOverlay('保存中，请稍等...');
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let formReview = PF.JSON(this.formReview);
            formReview.reviewResult = reviewResult;
            IOT.getServerData('/asn/masters/review', 'get', formReview, (ret) => {
              IOT.hideOverlay();
              if (ret.code === 200) {
                IOT.tips('审核成功！', 'success');
                this.getCaseNowList();
                this.dialogReviewVisible = false;
              } else {
                IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
              }
            });
            this.resetForm(formName);
            this.dialogReviewVisible = false;
          } else {
            IOT.hideOverlay();
            console.error('error submit!!');
            return false;
          }
        });
      },
      getCaseNowList() {
        IOT.getServerData(
          '/case/details/nowList',
          'get',
          {
            billNo: this.searchData.billNo,
          },
          (ret) => {
            if (ret.code === 200) {
              if (ret.rows.length > 0) {
                this.formReview.caseDetailId = ret.rows[0].caseDetailId;
              } else {
                this.formReview.caseDetailId = null;
              }
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          }
        );
      },
      showAcceptModal(row) {
        // for(let key in this.formAccept){
        //   if(PF.isNull(row[key])){
        //     this.formAccept[key] = row[key];
        //   }
        // }
        this.formAccept = row;
        this.formAccept.asnBillNo = row.billNo;
        this.formAccept.asnDetailNo = row.detailNo;
        this.formAccept.billType = 2;
        this.formAccept.toQuantity = PF.subtraction(this.formAccept.expectQuantity, this.formAccept.acceptQuantity);
        PF.getSysParamUdfData('acceptExcess', [], (data, udfs) => {
          this.acceptExcess = 1 + Number(udfs[0].value) / 100;
        });

        this.getPackDetailData(row.packCode);
        this.getBatchTacticData(row);
        this.dialogAcceptVisible = true;
      },
      getBatchTacticData(row) {
        IOT.getServerData(
          '/batch/tactics/findByOrganizationOrItemInfo',
          'get',
          {
            organizationId: row.organizationId,
            itemCode: row.itemCode,
          },
          (ret) => {
            if (ret.code === 200) {
              let list2 = PF.JSON(ret.rows[0]);
              let detailDetails = list2.batchTacticDetailDetails;
              let testDetailDetails = [];
              list2.batchTacticDetails[12].values = [];
              list2.batchTacticDetails[13].values = [];

              for (let i = 0; i < detailDetails.length; i++) {
                if (detailDetails[i].detailCode === 'batchAttribute1') {
                  // testDetailDetails.push(detailDetails[i])
                  list2.batchTacticDetails[12].values.push(detailDetails[i]);
                } else if (detailDetails[i].detailCode === 'batchAttribute2') {
                  list2.batchTacticDetails[13].values.push(detailDetails[i]);
                }
              }

              let batchTacticDetails = list2.batchTacticDetails;
              let testBatchTacticDetails = [];
              for (let j = 0; j < batchTacticDetails.length; j++) {
                if (batchTacticDetails[j].state === 1) {
                  this.formAccept[batchTacticDetails[j].detailCode] = '';
                  testBatchTacticDetails.push(batchTacticDetails[j]);
                  if (batchTacticDetails[j].isRequired === 1) {
                    this.rulesAccept[batchTacticDetails[j].detailCode][0].required = true;
                  }
                  if (batchTacticDetails[j].detailCode === 'supplierCode') {
                    this.formAccept[batchTacticDetails[j].detailCode] = row.supplierCode;
                  } else if (batchTacticDetails[j].detailCode === 'detailNo') {
                    this.formAccept[batchTacticDetails[j].detailCode] = row.detailNo;
                  } else if (batchTacticDetails[j].detailCode === 'inDate') {
                    this.formAccept[batchTacticDetails[j].detailCode] = PF.getNowFormatDate();
                  }
                }
              }

              list2.batchTacticDetailDetails = testDetailDetails;
              list2.batchTacticDetails = testBatchTacticDetails;

              this.batchTactics = list2;
            } else {
              IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
          }
        );
      },
      showSelectCellModal() {
        if (!PF.isNull(this.formAccept.wareId)) {
          IOT.tips('未选择仓库！', 'error');
          return false;
        }
        this.searchSelectCell.wareId = this.formAccept.wareId;
        this.getCellData();
        this.getAreaData();
        this.innerVisibleCell = true;
      },
      selectCell(cellCode) {
        this.formAccept.toCellCode = cellCode;
        this.innerVisibleCell = false;
      },
      commitAccept() {
        let that = this;
        IOT.showOverlay('保存中，请稍等...');
        let form = PF.JSON(this.formAccept);
        if (form.toQuantity === 0) {
          IOT.tips('数量不得为零！', 'error', 1000, () => {
            IOT.hideOverlay();
          });
          return false;
        }
        if (form.toCellCode === '') {
          IOT.tips('货位不得为空！', 'error', 1000, () => {
            IOT.hideOverlay();
          });
          return false;
        }

        form.asnDetailNo = form.detailNo;
        // form[i].toPackDetailId = form[i].packDetailId;
        // form[i].toPackDescribe = form[i].packDescribe;
        form.asnBillNo = form.billNo;

        // 主单位数量
        form.toQuantity = form.toQuantity * form.toTransRatio;
        IOT.getServerData('/accept/records/accept', 'post', this.formAccept, (ret) => {
          IOT.hideOverlay();
          if (ret.code === 200) {
            IOT.tips('验收成功！', 'success', 1000, () => {
              that.getAsnDetailData();
              that.getAsnRecordData();
              that.resetForm('formAccept');
              that.handleClose4();
            });
          } else {
            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
          }
        });
      },
    },
    // 获取日志
    getLogList() {
        this.loading = true;
        let url = '/system/log/list';
        IOT.getServerData(url, 'post', this.logSearchData, (ret) => {
            this.loading = false;
            // if (ret.code === 200) {
                this.exDetails = ret.list
                this.logSearchData.total = ret.total;
                this.logSearchData.pageNum = ret.pageNum;
            // } else {
            //     IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            // }
        }, true);
    },
    created() {
      this.getCarrierData(); //物流商
      // this.getCaseNowList();
      // PF.getSysParamUdfData('asnUdfHs', [], (data, udfs) => {
      //   this.asnUdfHs = udfs;
      // });
      // PF.getSysParamUdfData('asnUdfDs', [], (data, udfs) => {
      //   this.asnUdfDs = udfs;
      // });
      this.getAsnMasterData(); //form
      this.getAsnDetailData(); //产品信息
      this.getAsnRecordData(); //入库记录
      // this.getAsnShipDetailData();
      // PF.getDataDictUdfData('asnType', (rows) => {
      //   this.asnTypes = rows;
      // });
      // PF.getDataDictUdfData('qcType', (rows) => {
      //   this.qcTypes = rows;
      // });
    },
    mounted() {
      this.getLogList();
    },
  };
</script>

<style>
  .custom-tooltip {
    max-width: 400px !important; /* 设置 Tooltip 的最大宽度 */
  }
</style>

<style scoped lang="less">
  .asnBillDetail {
  }
  :deep(.el-form-item) {
    margin-bottom: 0;
  }
</style>
